import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useForm } from "../../../hooks/useForm"

export const withContactForm = Component => ({ name = "ContactForm" }) => {
  const { contact } = useStaticQuery(graphql`
    query SANITY_PAGE_CONTACT_FORM {
      contact: sanityPageContact {
        contactFormTitle
        contactFormFirstNameLabel
        contactFormLastNameLabel
        contactFormEmailLabel
        contactFormPhoneLabel
        contactFormOrderNumberLabel
        contactFormTypeLabel
        contactFormMessageLabel
        additionalSubmitText
        additionalSubmittingText
        additionalSuccessText
      }
    }
  `)

  const {
    contactFormTitle,
    contactFormFirstNameLabel,
    contactFormLastNameLabel,
    contactFormEmailLabel,
    contactFormPhoneLabel,
    contactFormOrderNumberLabel,
    contactFormTypeLabel,
    contactFormMessageLabel,
    additionalSubmitText,
    additionalSubmittingText,
    additionalSuccessText,
  } = contact || {}

  const { getForm, submitForm, data, setData, loading, errors, success } = useForm()

  const form = getForm("contact")

  const handleSubmit = async event => {
    event.preventDefault()
    await submitForm(form, data)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const typeOptions = Object.values(form?.types || {})?.map(type => ({
    label: type?.title,
    value: type?.title,
  }))

  useEffect(() => {
    if (!data?.type) {
      setData(prevState => ({
        ...prevState,
        type: Object.values(form?.types || {})?.map(type => type.title)?.[0],
      }))
    }
  }, [data?.type, form?.types, setData])

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      success={success}
      typeOptions={typeOptions}
      title={contactFormTitle}
      contactFormFirstNameLabel={contactFormFirstNameLabel}
      contactFormLastNameLabel={contactFormLastNameLabel}
      contactFormEmailLabel={contactFormEmailLabel}
      contactFormPhoneLabel={contactFormPhoneLabel}
      contactFormOrderNumberLabel={contactFormOrderNumberLabel}
      contactFormTypeLabel={contactFormTypeLabel}
      contactFormMessageLabel={contactFormMessageLabel}
      additionalSubmitText={additionalSubmitText}
      additionalSubmittingText={additionalSubmittingText}
      additionalSuccessText={additionalSuccessText}
    />
  )
}
