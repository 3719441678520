import React from "react"
import { Link } from "gatsby"

import { Crumb, Outer, Inner, Next, Crumbs } from "./BreadCrumbsStyles"
import { withBreadCrumbs } from "./withBreadCrumbs"

export const BreadCrumbs = withBreadCrumbs(({ paths, children, fullWidth }) => {
  return (
    <Outer>
      <Inner fullWidth={fullWidth}>
        <Crumbs>
          {paths?.map((path, index) => {
            return paths?.length > index + 1 ? (
              <>
                <Crumb key={path.title} as={path?.url ? Link : null} to={path?.url}>
                  {path.title}
                </Crumb>
                <Next>&nbsp;&gt;&nbsp;</Next>
              </>
            ) : (
              <Crumb key={path.title} as={"h1"}>
                {path?.title}
              </Crumb>
            )
          })}
        </Crumbs>
        {children}
      </Inner>
    </Outer>
  )
})
