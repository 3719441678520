import tw, { styled } from "twin.macro"
import { Body } from "../../Styled/Text"

export const SuccessMessage = tw.p`
  text-12 leading-none mt-2-4
`

export const ErrorMessage = tw.p`
  text-12 text-red leading-none mt-2-4
`

export const Form = tw.form`
  relative
`

export const RequiredLabel = styled(Body)`
  ${tw`absolute top-0 right-0`}
`
