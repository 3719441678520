import React from "react"

export const withContact = Component => ({ name = "Contact", page }) => {
  const { title } = page

  const paths = [{ title }]

  Component.displayName = name
  return <Component paths={paths} />
}
