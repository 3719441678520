import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCore } from "../../../hooks/useCore"
import { useImage } from "../../../hooks/useImage"

export const withContactContent = Component => ({ name = "ContactContent" }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const { getFluidImage } = useImage()

  const { contact } = useStaticQuery(graphql`
    query SANITY_PAGE_CONTACT_CONTENT {
      contact: sanityPageContact {
        contentTitle
        openingHours: _rawOpeningHours(resolveReferences: { maxDepth: 2 })
        email
        phone
        address: _rawAddress(resolveReferences: { maxDepth: 2 })
        image: _rawImage(resolveReferences: { maxDepth: 2 })
        liveChatLabel
        liveChatAvailableLabel
        emailLabel
        phoneLabel
      }
    }
  `)

  const { contentTitle, openingHours, email, phone, address, image: originalImage, liveChatLabel, liveChatAvailableLabel, emailLabel, phoneLabel } =
    contact || {}

  const image = getFluidImage(originalImage, { maxWidth: 340 })

  const handleChatClick = () => {
    isBrowser && window?.GorgiasChat ? window.GorgiasChat.open() : null
  }

  const isChatAvailable = () => {
    isBrowser && window?.GorgiasChat ? window.GorgiasChat.isBusinessHours() : false
  }

  Component.displayName = name
  return (
    <Component
      contentTitle={contentTitle}
      openingHours={openingHours}
      email={email}
      phone={phone}
      address={address}
      image={image}
      liveChatLabel={liveChatLabel}
      liveChatAvailableLabel={liveChatAvailableLabel}
      emailLabel={emailLabel}
      phoneLabel={phoneLabel}
      handleChatClick={handleChatClick}
      isChatAvailable={isChatAvailable}
    />
  )
}
