import React from "react"

import { withContact } from "./withContact"
import { Outer, Inner, Section } from "./ContactStyles"
import { ContactForm as Form } from "./Form/ContactForm"
import { ContactContent } from "./Content/ContactContent"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"

export const Contact = withContact(({ paths }) => (
  <>
    <BreadCrumbs paths={paths} />
    <Outer>
      <Inner>
        <Section>
          <ContactContent />
        </Section>
      </Inner>
      <Inner>
        <Section>
          <Form />
        </Section>
      </Inner>
    </Outer>
  </>
))
