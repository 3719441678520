import React from "react"

import { Body } from "../Styled/Text"
import { StyledTextArea } from "../Styled/Form"

export const Textarea = ({ label, spacing = "", className = "", required = false, ...props }) => (
  <label className={className} htmlFor={label}>
    <Body spacing={spacing}>{required ? `${label}*` : label}</Body>
    <StyledTextArea spacing={spacing} required={required} {...props} />
  </label>
)
