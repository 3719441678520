import React from "react"

import { withContactForm } from "./withContactForm"
import { SuccessMessage, ErrorMessage, Form, RequiredLabel } from "./ContactFormStyles"
import { Title } from "../ContactStyles"
import { StyledButton } from "../../Styled/Button"
import { Input } from "../../Form/Input"
import { Dropdown } from "../../Form/Dropdown"
import { Textarea } from "../../Form/Textarea"

export const ContactForm = withContactForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    errors,
    success,
    title,
    typeOptions,
    contactFormFirstNameLabel,
    contactFormLastNameLabel,
    contactFormEmailLabel,
    contactFormPhoneLabel,
    contactFormOrderNumberLabel,
    contactFormTypeLabel,
    contactFormMessageLabel,
    additionalSubmitText,
    additionalSubmittingText,
    additionalSuccessText,
  }) => (
    <>
      <Title>{title}</Title>
      <Form onSubmit={handleSubmit}>
        <RequiredLabel>*Required</RequiredLabel>
        <Input
          label={contactFormFirstNameLabel}
          name="firstName"
          type="text"
          value={data?.firstName}
          onChange={handleChange}
          required
          spacing={"small"}
        />
        <Input
          label={contactFormLastNameLabel}
          name="lastName"
          type="text"
          value={data?.lastName}
          onChange={handleChange}
          required
          spacing={"small"}
        />
        <Input label={contactFormEmailLabel} name="email" type="email" value={data?.email} onChange={handleChange} required spacing={"small"} />
        <Input label={contactFormPhoneLabel} name="phone" type="text" value={data?.phone} onChange={handleChange} spacing={"small"} />
        <Input
          label={contactFormOrderNumberLabel}
          name="orderNumber"
          type="text"
          value={data?.orderNumber}
          onChange={handleChange}
          spacing={"small"}
        />
        <Dropdown
          label={contactFormTypeLabel}
          name="type"
          defaultValue={typeOptions[0]}
          options={typeOptions}
          value={data?.type}
          handleChange={handleChange}
          spacing={"small"}
        />
        <Textarea label={contactFormMessageLabel} name="message" value={data?.message} onChange={handleChange} required spacing={"small"} />
        <StyledButton type={`submit`} disabled={loading} size={"primary"} colour={"yellow"} wide>
          {loading ? additionalSubmittingText : additionalSubmitText}
        </StyledButton>
        {errors?.length > 0 ? (
          <>
            {errors?.map(error => (
              <ErrorMessage key={error}>{error}</ErrorMessage>
            ))}
          </>
        ) : null}
        {success && <SuccessMessage>{additionalSuccessText}</SuccessMessage>}
      </Form>
    </>
  )
)
