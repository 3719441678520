import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

import { Body } from "../Styled/Text"

export const Outer = tw.div`
  w-full
`

export const Inner = styled(StyledContainer)`
  ${tw`flex flex-row justify-between items-center lg:px-0 hd:max-w-xl`}
  ${({ fullWidth }) => fullWidth && tw`max-w-full hd:max-w-full`}
  ${({ fullWidth }) => fullWidth && `padding: 0 0.625rem !important;`}

`

export const Crumbs = tw.div`
  flex flex-row items-center align-middle py-0-8
`

export const Crumb = styled(Body)``

export const Next = styled(Body)``
