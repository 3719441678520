import React from "react"

import { withContactContent } from "./withContactContent"
import { Title } from "../ContactStyles"
import { ImageWrapper, Link, ChatWrapper } from "./ContactContentStyles"
import { Image } from "../../Image/Image"
import { RichText } from "../../RichText/RichText"
import { Body } from "../../Styled/Text"

export const ContactContent = withContactContent(
  ({
    contentTitle,
    openingHours,
    email,
    phone,
    address,
    image,
    liveChatLabel,
    liveChatAvailableLabel,
    emailLabel,
    phoneLabel,
    handleChatClick,
    isChatAvailable,
  }) => (
    <>
      <Title>{contentTitle}</Title>
      <RichText spacing={"small"}>{openingHours}</RichText>
      <ChatWrapper onClick={handleChatClick} title={"open gorgias chat"}>
        <Body spacing={"default"}>{liveChatLabel}</Body>:&nbsp;
        {isChatAvailable ? <Link>{liveChatAvailableLabel}</Link> : null}
      </ChatWrapper>
      <Body spacing={"default"}>
        {emailLabel}:{" "}
        <Link href={`mailto:${email}`} title={emailLabel}>
          {email}
        </Link>
      </Body>
      <Body spacing={"default"}>
        {phoneLabel}:{" "}
        <a href={`tel:${phone}`} title={phoneLabel}>
          {phone}
        </a>
      </Body>
      <RichText spacing={"small"}>{address}</RichText>
      <ImageWrapper>
        <Image image={image} />
      </ImageWrapper>
    </>
  )
)
