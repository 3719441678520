import tw from "twin.macro"

export const ImageWrapper = tw.div`
  pt-4-2 hidden md:block
`

export const Link = tw.a`
  underline text-black
`

export const ChatWrapper = tw.button`
  flex flex-row
`
